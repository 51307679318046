<template>
  <section v-if="dataLoaded">

    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'dashboard' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'view-mycompany' }">
              {{ resourceName }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :active="true">
              Edit
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
      <b-col
        md="12"
        class="mb-2 text-right"
      >
        <b-button
          variant="primary"
          :to="{ name: 'view-mycompany' }"
        >
          <feather-icon
            icon="EyeIcon"
            size="16"
            class="align-text-top"
          />  View {{ resourceName }}
        </b-button>
        <!-- <b-button
          variant="primary"
          :to="{name: 'browse-companies'}"
        >
          <feather-icon
            icon="ListIcon"
            size="16"
            class="align-text-top"
          />  Manage {{ resourceNamePlural }}
        </b-button> -->
      </b-col>
    </b-row>

    <!-- invite user card  -->
    <b-card class="p-1">
      <b-card-title class="pl-1 border-left-primary border-left-3">
        Invite User
      </b-card-title>
      <!-- form -->
      <validation-observer
        ref="inviteForm"
        #default="{invalid, dirty}"
      >
        <b-form @submit.prevent="sendCompanyInvite">
          <b-row>
            <!-- email -->
            <b-col cols="12">
              <b-form-group
                label="Email"
                label-for="inviteEmail"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                  vid="recipient_email"
                >
                  <b-form-input
                    id="inviteEmail"
                    v-model="inviteEmail"
                    name="email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="user email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit -->
            <b-col cols="12">
              <b-button
                type="submit"
                variant="primary"
                :disabled="invalid || !dirty"
              >
                Send Invite
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <!-- edit resource card  -->
    <b-card class="p-1">
      <b-card-title class="pl-1 border-left-primary border-left-3">
        Edit {{ resourceName }}
      </b-card-title>
      <!-- form -->
      <validation-observer
        ref="resourceForm"
        #default="{invalid, dirty}"
      >
        <b-form @submit.prevent="updateCompany">
          <b-row>
            <!-- name -->
            <b-col cols="12">
              <b-form-group
                label="Name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                  vid="name"
                >
                  <b-form-input
                    id="name"
                    v-model="companyName"
                    name="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="company name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit -->
            <b-col cols="12">
              <b-button
                type="submit"
                variant="primary"
                :disabled="invalid || !dirty"
              >
                Update
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BCard,
  BFormGroup, BFormInput, BForm, BButton, BCardTitle,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCardTitle,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      resourceName: 'Company',
      resourceNamePlural: 'Companies',
      companyName: '',
      inviteEmail: '',
      required,
      email,
      dataLoaded: false,
      errorModalMessage: null,
    }
  },
  computed: {
    resourceId() {
      return this.$route.params.id
    },
  },
  beforeCreate() {
    useJwt.getMyCompany(this.$route.params.id)
      .then(response => {
        console.log('getMyCompany', response.data.data)
        const resourceData = response.data.data
        // populate resource form fields
        this.companyName = resourceData.name
        // indidate that data fetch was successful
        this.dataLoaded = true
      })
      .catch(error => {
        if (error.response.status === 403) { // forbidden
          // this.$router.push({ name: 'error-404' })
        } else if (error.response.status === 404) { // not found
          // this.$router.push({ name: 'error-404' })
        }
      })
  },
  methods: {
    updateCompany() {
      this.$refs.resourceForm.validate().then(success => {
        if (success) {
          // reset form validation state
          // so we can track if form data is dirty
          this.$refs.resourceForm.reset()
          useJwt.updateMyCompany({
            name: this.companyName,
          })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Updated',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Company updated successfully.',
                },
              })
            })
            .catch(error => {
              if (error.response.status === 400) {
                // highlights all applicable erroneous inputs mentioned by the server.
                this.$refs.resourceForm.setErrors(error.response.data.error)
              }
            })
        }
      })
    },
    sendCompanyInvite() {
      this.$refs.inviteForm.validate().then(success => {
        if (success) {
          // reset form validation state
          // so we can track if form data is dirty
          this.$refs.inviteForm.reset()
          useJwt.sendMyCompanyInvite({
            email: this.inviteEmail,
          })
            .then(response => {
              console.log('sendMyCompanyInvite', response.data.data) // DELETE LATER
              /* this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Invitation Sent',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `Your invitation was sent successfully to ${this.inviteEmail}`,
                },
              }) */
              this.$bvModal.msgBoxOk(`Your invitation was sent successfully to ${this.inviteEmail}`, {
                title: 'Sent!',
                hideHeaderClose: true,
                okVariant: 'success',
                centered: true,
                modalClass: 'modal-success',
              })
              // reset invite email field
              this.inviteEmail = ''
            })
            .catch(error => {
              if (error.response.status === 400) {
                // highlights all applicable erroneous inputs mentioned by the server.
                this.$refs.inviteForm.setErrors(error.response.data.error)
              }
              this.errorModalMessage = error.response.error
              this.showError()
            })
        }
      })
    },
    showError() {
      this.$bvModal.msgBoxOk(this.errorModalMessage, {
        title: 'Error',
        hideHeaderClose: true,
        okVariant: 'danger',
        centered: true,
        modalClass: 'modal-warning',
      })
    },
  },
}
</script>

<style lang="scss">

</style>
